<template>
  <v-card outlined>
    <v-card-actions class="d-flex py-1">
      <v-card-title class="pl-0">
        Dados do Contrato
      </v-card-title>

      <v-spacer></v-spacer>

      <v-btn
        class="px-0"
        icon
        @click="$emit('closeModal')"
      >
        <v-icon>
          {{ icons.mdiWindowClose }}
        </v-icon>
      </v-btn>
    </v-card-actions>

    <v-card>
      <v-card-text
        class="pb-1"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="py-0"
          >
            <v-text-field
              v-model="modalOptions.itemList.contract_number"
              outlined
              dense
              readonly
              label="Contrato"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="6"
            class="py-0"
          >
            <v-text-field
              v-model="modalOptions.itemList.cpf_cnpj"
              outlined
              readonly
              dense
              label="CPF do Cliente"
            />
          </v-col>
          <v-col>
            <v-text-field
              v-model="modalOptions.itemList.client_name"
              outlined
              readonly
              dense
              label="Nome do Cliente"
            />
          </v-col>
          <v-col class="pt-0 mt-0">
            <v-checkbox
              class="mt-0 pt-0"
              label="Não pertence ao grupo Motoca"
              color="warning"
              hide-details
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex pt-5 justify-center">
        <div>
          <v-btn
            color="success"
            class="me-3"
            @click="findContract()"
          >
            <v-icon>{{ icons.mdiCheckDecagram }}</v-icon>
            <span>Confirmar</span>
          </v-btn>
          <v-btn
            color="error"
            class="mt-1 me-3"
            @click="$emit('closeModal')"
          >
            <span>Cancelar</span>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-card>
</template>
<script>
import axiosIns from '@/plugins/axios'
import {
  mdiCancel, mdiCheckDecagram, mdiDraw, mdiFileSendOutline, mdiFilter, mdiPlus, mdiWindowClose,
} from '@mdi/js'

export default {
  props: {
    modalOptions: {
      type: Object,
      default: () => ({}),
    },
    updatedTable: {
      type: Function,
      required: true,
      default: () => {},
    },
  },

  data: () => ({
    icons: {
      mdiPlus,
      mdiCancel,
      mdiCheckDecagram,
      mdiDraw,
      mdiFileSendOutline,
      mdiWindowClose,
      mdiFilter,
    },
  }),

  computed: {},

  created() {},

  // CONSULTAR NO BACKEND SE EXISTE,
  // SE SIM EXIBIR O NOME DO VENDEDOR EM UM CAMPO READONLY E ATUALIZAR O USER_ID DA TABELA PELO ID DO VENDEDOR ENCONTRADO
  // CASO NÃO EXISTA, FALAR PARA MARCAR A OPÇÃO "NÃO PERTENCE AO GRUPO MOTOCA"
  // SÓ ENTÃO LIBERAR O BOTÃO DE CONFIRMAR
  methods: {
    async findContract() {
      const body = {
        id: this.modalOptions.editItem,
        does_not_belong: true,
      }
      await axiosIns.post('/api/v1/integration/delivery/does_not_belong_company', body)
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Tudo certo!',
            text: 'Os dados foram removidos!',
            showConfirmButton: false,
            timer: 2000,
          })

          this.$emit('closeModal')
          this.updatedTable()
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao remover dados!',
            showConfirmButton: false,
            text: error,
            timer: 2000,
          })

          this.$emit('closeModal')
        })
    },
  },
}
</script>
