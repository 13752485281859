<template>
  <div>
    <v-card>
      <!-- list filters -->

      <v-card-title>
        Ajuste de Reserva
      </v-card-title>

      <v-divider></v-divider>

      <!-- filter quicksearch -->
      <v-card-text class="d-flex align-center flex-wrap">
        <!-- search -->
        <v-row>
          <v-col
            cols="12"
            md="4"
            lg="4"
            sm="4"
            class="pb-1"
          >
            <v-select
              v-model="filterOptions.origin"
              label="Origem"
              outlined
              hide-details
              dense
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="4"
            sm="4"
            class="pb-1"
          >
            <v-select
              v-model="filterOptions.company"
              label="Filial"
              outlined
              hide-details
              dense
            >
            </v-select>
          </v-col>
          <v-col
            cols="12"
            md="4"
            lg="4"
            sm="4"
            class="pb-3"
          >
            <v-select
              v-model="filterOptions.payroll"
              label="Folha"
              outlined
              hide-details
              dense
            >
            </v-select>
          </v-col>
        </v-row>

        <v-spacer></v-spacer>

        <div class="d-flex align-center flex-wrap mt-2">
          <v-btn
            color="primary"
            class="mb-2 me-3"
          >
            <v-icon>{{ icons.mdiFilter }}</v-icon>
            <span>Filtrar</span>
          </v-btn>

          <v-btn
            color="secondary"
            outlined
            class="mb-2"
            @click="filterReset"
          >
            <v-icon
              size="17"
              class="me-1"
            >
              {{ icons.mdiFilterRemove }}
            </v-icon>
            <span>Limpar</span>
          </v-btn>
        </div>
      </v-card-text>

      <!--table-->
      <v-data-table
        :headers="headers"
        :items="deliveryAdjustmentList"
        :loading="loading"
        loading-text="Carregando dados..."
        :page.sync="pageOptions.page"
        :items-per-page="pageOptions.itemsPerPage"
        fixed-header
        :height="heightDinamic"
        hide-default-footer
        disable-sort
        @page-count="pageCount = $event"
      >
        <!-- action (edit, delete, etc) -->
        <template #[`item.actions`]="{ item }">
          <v-icon
            v-if="item.id === modalOptions.loadingAction? false : true"
            medium
            class="me-2"
            color="error"
            @click="getContractInfo(item)"
          >
            {{ icons.mdiCloseOctagonOutline }}
          </v-icon>
          <v-progress-circular
            v-if="item.id === modalOptions.loadingAction? true : false"
            indeterminate
            :size="20"
            color="gray"
          ></v-progress-circular>
        </template>
        <!--data empty-->
        <template v-slot:no-data>
          {{ noDataText }}
        </template>
      </v-data-table>

      <v-divider class="mt-4"></v-divider>

      <!-- pagination -->
      <v-card-text class="pt-2 pb-1">
        <v-row>
          <v-col
            lg="12"
            cols="12"
            class="d-flex justify-center"
          >
            <v-pagination
              v-model="pageOptions.page"
              total-visible="6"
              :length="pageOptions.pageCount"
              @input="updatePage"
            ></v-pagination>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- <v-dialog
      v-model="modalOptions.modal"
      width="900"
    >
      <delivery-adjustment-modal
        :modal-options="modalOptions"
        :updated-table="updatedTable"
        @closeModal="closeModal"
      >
      </delivery-adjustment-modal>
    </v-dialog> -->
  </div>
</template>

<script>
import axiosIns from '@axios'
import {
  mdiAccountOutline,
  mdiCalendar, mdiCloseOctagonOutline, mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiEyeOutline,
  mdiFileDocumentOutline,
  mdiFilter,
  mdiFilterMenu,
  mdiFilterRemove,
  mdiLoading,
  mdiPencilOutline,
  mdiPlus,
  mdiSquareEditOutline,
  mdiWhatsapp,
} from '@mdi/js'
import DeliveryAdjustmentModal from './DeliveryAdjustmentModal.vue'

export default {
  components: {
    DeliveryAdjustmentModal,
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    icons: {
      mdiSquareEditOutline,
      mdiDotsVertical,
      mdiPencilOutline,
      mdiFileDocumentOutline,
      mdiDeleteOutline,
      mdiPlus,
      mdiExportVariant,
      mdiAccountOutline,
      mdiFilter,
      mdiFilterRemove,
      mdiWhatsapp,
      mdiCalendar,
      mdiFilterMenu,
      mdiLoading,
      mdiEyeOutline,
      mdiCloseOctagonOutline,
    },

    /* filter */
    filterOptions: {
      origin: [],
      company: [],
      payroll: [],
    },
    dateMenu: false,
    dueDayList: ['20', '18', '15', '11', '1'],
    salesPlanList: [
      'Especial',
      'Super Legal',
      'Master',
      'Conquista',
      'TRX E CRF',
      'Mega F?cil',
      'Minha Scooter Honda',
      'Multichances',
      'Vou de Honda',
      'Vou de Honda +',
    ],

    /* data table */
    deliveryAdjustmentList: [],
    noDataText: 'Nenhum registro encontrado',
    heightDinamic: '400px',
    loading: true,
    headers: [
      { text: 'CONTRATO', value: 'contract_number', align: 'center' },
      { text: 'CPF CLIENTE', value: 'cpf_cnpj', align: 'center' },
      { text: 'CLIENTE', value: 'client_name', align: 'center' },
      { text: 'MODELO', value: 'vehicle_model', align: 'center' },
      { text: 'NF', value: 'invoice_number', align: 'center' },
      { text: 'EMISSÃO', value: 'invoice_date', align: 'center' },
      { text: 'Não pertence', value: 'actions', align: 'center' },
    ],

    /* router */
    routeOptions: {
      resource: 'not_identified',
      method: 'get',
      params: {},
    },

    /* pagination */
    pageOptions: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
    },

    /* modal */
    modalOptions: {
      modal: false,
      loadingAction: '',
      editItem: '',
      itemList: [],
    },
  }),

  watch: {
    //
  },

  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      await axiosIns
        .get('/api/v1/integration/delivery/not_identified')
        .then(res => {
          this.heightDinamic = `${window.screen.height * 0.45}px`
          res.data.data.map(value => {
            this.deliveryAdjustmentList.push({
              id: value.id,
              cpf_cnpj: value.cpf_cnpj,
              contract_number: value.contract_number,
              client_name: value.client_name,
              vehicle_model: value.vehicle_model,
              invoice_number: value.invoice_number,
              group_quota: value.group_quota,
              invoice_date: value.invoice_date,
            })
          })
          this.pageOptions.itemsPerPage = res.data.meta.per_page
          this.pageOptions.pageCount = res.data.meta.last_page
          this.pageOptions.page = res.data.meta.current_page
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          this.noDataText = `Contate o suporte do sistema: ${err}`
        })
    },

    async updatePage() {
      this.loading = true
      this.deliveryAdjustmentList = []
      if (this.routeOptions.method === 'get') {
        await axiosIns
          .get(`/api/v1/integration/delivery/${this.routeOptions.resource}?page=${this.pageOptions.page}`)
          .then(res => {
            res.data.data.map(value => {
              this.deliveryAdjustmentList.push({
                id: value.id,
                contract_number: value.contract_number,
                client_name: value.client_name,
                vehicle_model: value.vehicle_model,
                invoice_number: value.invoice_number,
                group_quota: value.group_quota,
                invoice_date: value.invoice_date,
                cpf_cnpj: value.cpf_cnpj,
              })
            })
            this.pageOptions.itemsPerPage = res.data.meta.per_page
            this.pageOptions.pageCount = res.data.meta.last_page
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.noDataText = `Contate o suporte do sistema: ${err}`
          })
      } else if (this.routeOptions.method === 'post') {
        await axiosIns
          .post(
            `/api/v1/integration/delivery/${this.routeOptions.resource}?page=${this.pageOptions.page}`,
            this.routeOptions.params,
          )
          .then(res => {
            res.data.data.map(value => {
              this.deliveryAdjustmentList.push({
                id: value.id,
                contract_number: value.contract_number,
                client_name: value.client_name,
                vehicle_model: value.vehicle_model,
                invoice_number: value.invoice_number,
                group_quota: value.group_quota,
                invoice_date: value.invoice_date,
                cpf_cnpj: value.cpf_cnpj,
              })
            })
            this.pageOptions.itemsPerPage = res.data.meta.per_page
            this.pageOptions.pageCount = res.data.meta.last_page
            this.loading = false
          })
          .catch(err => {
            this.loading = false
            this.noDataText = `Contate o suporte do sistema: ${err}`
          })
      }
    },

    // async filter() {
    //   this.loading = true
    //   this.routeOptions = {
    //     resource: 'reservation_group_location',
    //     method: 'post',
    //     params: this.filterOptions,
    //   }
    //   this.deliveryAdjustmentList = []
    //   await axiosIns
    //     .post('/api/v1/integration/reserve_group/reservation_group_location', this.routeOptions.params)
    //     .then(res => {
    //       res.data.data.map(value => {
    //         this.deliveryAdjustmentList.push({
    //           id: value.id,
    //           group_number: value.group_number,
    //           sales_plan: value.sales_plan,
    //           reservation: value.reservation,
    //           assembly_date_next: value.assembly_date_next,
    //           bid_highest: value.bid_highest,
    //           bid_lowest: value.bid_lowest,
    //           due_day: value.due_day,
    //           assembly_number: value.assembly_number,
    //           parcel_number: value.parcel_number,
    //           average: value.average,
    //         })
    //       })
    //       this.pageOptions.page = res.data.meta.current_page
    //       this.pageOptions.itemsPerPage = res.data.meta.per_page
    //       this.pageOptions.pageCount = res.data.meta.last_page
    //       this.loading = false
    //     })
    //     .catch(err => {
    //       this.loading = false
    //       this.noDataText = `Contate o suporte do sistema: ${err}`
    //     })
    // },

    async filterReset() {
      this.loading = true
      this.deliveryAdjustmentList = []
      Object.keys(this.filterOptions).forEach(key => {
        this.filterOptions[key] = ''
      })
      this.routeOptions = {
        resource: 'not_identified',
        method: 'get',
        params: {},
      }

      this.initialize()
    },

    // chatWhatsapp(phone) {
    //   window.open(`https://wa.me/55${phone}`, '_blank')
    // },

    async getContractInfo(item) {
      this.modalOptions.loadingAction = item.id

      const body = {
        id: item.id,
        does_not_belong: true,
      }

      await axiosIns.post('/api/v1/integration/delivery/does_not_belong_company', body)
        .then(async () => {
          this.$swal({
            icon: 'success',
            title: 'Tudo certo!',
            text: 'Os dados foram removidos!',
            showConfirmButton: false,
            timer: 2000,
          })

          this.updatedTable()
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: 'Erro ao remover dados!',
            showConfirmButton: false,
            text: error,
            timer: 2000,
          })
        })
    },

    async updatedTable() {
      await this.updatePage()
    },

    closeModal() {
      this.modalOptions.modal = false
    },

  },
}
</script>
